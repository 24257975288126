export const defaultState = {isOnline:true, syncingLocalChanges:false}

export default function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "SET_IS_ONLINE":
      return { ...state, isOnline: payload }
    case "SET_SYNC_LOCAL_CHANGES":
      return { ...state, syncingLocalChanges: payload }
    default:
     return state
  }
}
