

export const sanitizeRoom = (room) => {
  return {
    roomId: room.roomId,
    number: room.number,
    sector: room.sector,
    type: room.type,
    state: room.state,
  };
}