
export const sanitizePayment = (payment) => {
  const {
    paymentId,
    amount,
    date,
    isRefund,
    method,
    specificUsername,
  } = payment;
  return {
    paymentId,
    amount,
    date,
    isRefund,
    method,
    specificUsername,
  };
}