import store from '../../../redux/store';
import { MejorarIngreso, AgregarServicioAReserva,AgregarPagoAReserva } from '../../../Funciones/utils';

const joinCacheWithReservation = (reservationList, type) => {
    let reservationListAux=[...reservationList]
    reservationListAux = joinAddReservationWithCache(reservationListAux,type);    
    reservationListAux = joinModifyReservationWithCache(reservationListAux,type);
    reservationListAux = joinCacheWithService(reservationListAux,type);
    reservationListAux = joinCacheWithPayment(reservationListAux,type);
    return reservationListAux
}

const joinAddReservationWithCache = (reservationList, type) => {
    const { newReservationsQueue } = store.getState().queueReducer
    // filter the reservations that are not in reservationList
    const allServicesList = reservationList.map(x=>x.servicesList).flat()
    const reservationsNotInList = reservationList.filter(x => !newReservationsQueue.find(y=>y.reservationId==x.reservationId)).map(x=>MejorarIngreso({...x,serviceList:allServicesList.filter(y=>y.reservationId==x.reservationId)}) )
    
    return [...reservationsNotInList, ...newReservationsQueue.filter(x=>x.type == type)]
}

const joinModifyReservationWithCache = (reservationList, type) => {
    const { modifyReservationsQueue, servicesQueue } = store.getState().queueReducer

    // remove reservations that are in modifyReservationsQueue
    const filteredReservations = reservationList.filter(x => !modifyReservationsQueue.find(y=>y.reservationId==x.reservationId) || servicesQueue.find(y=>y.reservationId==x.reservationId) )
    const modifyReservationWithoutServices = modifyReservationsQueue.filter(x=>!servicesQueue.find(y=>y.reservationId==x.reservationId))
    return [...filteredReservations, ...modifyReservationWithoutServices.filter(x=>x.type == type)]
}

const joinCacheWithService = (reservationList, type) => {
    const { servicesQueue, cancelServicesQueue } = store.getState().queueReducer
    const reservationListAux = reservationList.map(reservation => {
        const services = servicesQueue.filter(y => y.reservationId == reservation.reservationId && !cancelServicesQueue.filter(x=>x.type == type).find(z=>z.serviceId==y.serviceId))
        if (services.length == 0) return reservation
        let reservationAux = {...reservation};
        for(let service of services){
            if(reservation.reservationId == service.reservationId){
                const newPaymentList = service.newPayment? [service.newPayment]:[];
                reservationAux = AgregarServicioAReserva(reservationAux, service.newService, newPaymentList, service.addDays)
            }
        }
        return MejorarIngreso(reservationAux);
    })
    return reservationListAux
}

const joinCacheWithPayment = (reservationList, type) => {
    const { paymentsQueue } = store.getState().queueReducer
    const reservationListAux = reservationList.map(reservation => {
        const payments = paymentsQueue.filter(x=>x.type == type).filter(y => y.reservationId == reservation.reservationId)
        if (payments.length == 0) return reservation
        let reservationAux = {...reservation};
        for(let payment of payments){
            if(reservation.reservationId == payment.reservationId){
                reservationAux = AgregarPagoAReserva(reservation, payment.serviceId, payment.newPayment)
            }
        }

        return MejorarIngreso(reservationAux);
    })
    return reservationListAux
}

export default joinCacheWithReservation

