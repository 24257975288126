
export const sanitizeVehicle = (vehicle) => {
  const { vehicleId, plate, brand, type, color } = vehicle;
  return {
    vehicleId,
    plate,
    brand,
    type,
    color,
  };
}