const dexie = require("dexie");
const db = new dexie.Dexie("lastPriceChange");
db.version(1).stores({
    lastPriceChange: "value",
  });

const saveLastPriceChange = async (lastPriceChange) => {
  try {
    await db.table(`lastPriceChange`).put({ value: lastPriceChange });

  } catch (error) {
    console.log(error);
  }
};

const getLastPriceChange = async () => {
  let lastPriceChange={};
  try {
   lastPriceChange = await db.table(`lastPriceChange`).toCollection().first();
  return lastPriceChange.value;
  } catch (error) {
    console.log(error);
  }
  return null;
};


export default {
    saveLastPriceChange,
    getLastPriceChange
};
