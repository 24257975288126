import { combineReducers,createStore,applyMiddleware } from 'redux'

import { 
    mainReducer, 
    printerReducer, 
    billReducer, 
    mapReducer, 
    customersReducer, 
    queueReducer,
    internetStatusReducer,
    banReducer,
    promotionsReducer
 } from './reducers';
import createSagaMiddleware from 'redux-saga'

//Sagas
import rootSaga from './sagas'
const sagaMiddleware = createSagaMiddleware()

// const store = createStore(mainReducer,applyMiddleware(sagaMiddleware))
const store = createStore(combineReducers({promotionsReducer,billReducer,mainReducer,printerReducer,mapReducer,customersReducer,queueReducer,internetStatusReducer,banReducer}),applyMiddleware(sagaMiddleware))
sagaMiddleware.run(rootSaga)


export default store