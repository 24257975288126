/*
{
{
    "reservationId": "087deb13-419c-4933-a655-88c8f8dfa663",
    "children": 0,
    "guests": "1",
    "checkoutEstimated": "2022-03-02T08:00:00-03:00",
    "starredCustomerId": "e81997e0-a954-435f-8871-33639faaff9f",
    "checkoutMade": null,
    "checkinEstimated": "2022-02-28T03:00:00+00:00",
    "checkinMade": null,
    "created": "2022-02-28T21:28:37+00:00",
    "components": null,
    "description": null,
    "customersList": [
        {
            "customerId": "e81997e0-a954-435f-8871-33639faaff9f",
            "fullName": "Agustín Mazzarotti",
            "birthdate": "2001-03-27T00:00:00.000Z",
            "dni": "42436193",
            "geo": "{\"name\":\"\",\"type\":\"city\",\"query\":\"8407\",\"value\":\"buenos aires\",\"county\":\"\",\"country\":\"argentina\",\"hitindex\":1,\"postcode\":\"8407\",\"postcodes\":[\"8407\"],\"countrycode\":\"ar\",\"administrative\":\"neuquén\"}",
            "phone": "1138570034",
            "email": "agusmazzarotti15@gmail.com",
            "age": 21
        }
    ],
    "state": "preingreso",
    "way": "preingreso",
    "type": "camping",
    "servicesList": [
        {
            "serviceId": "d48cb951-d6e5-4a74-8cec-c4b21b0a5d43",
            "cost": 0,
            "quantity": 0,
            "date": "2022-02-28T21:28:38+00:00",
            "name": "preingreso",
            "isPaid": null,
            "canceled": false,
            "paymentsList": [],
            "description": "Pre-ingreso",
            "components": "{\"auto\":1,\"moto\":0,\"carpa\":0,\"mayor\":1,\"menor\":0,\"camper\":0,\"mchico\":0,\"mgrande\":0,\"remolque\":0,\"bicicleta\":0}",
            "perNight": 0,
            "paid": 0,
            "due": 0
        }
    ],
    "roomsList": [],
    "vehiclesList": [
        {
            "vehicleId": "15272c14-ccfd-4173-8726-3e37665d6bd5",
            "plate": "AD540GB",
            "brand": "Renault",
            "type": "auto",
            "color": "rojo"
        }
    ],
    "otaId": null,
    "extrasList": null,
    "regularCustomer": null,
    "nights": 2,
    "isDeudor": true,
    "nightsDue": 182,
    "percentPayed": 0
}
}*/

const dexie = require('dexie');

const saveReservationList = async (type,reservationList) => {
    const db = new dexie.Dexie(type);
    db.version(1).stores({
        [`reservations-${type}`]: 'reservationId'
    });
    await db.table(`reservations-${type}`).bulkPut(reservationList);
};

const removeAllReservations = async (type) => {
    const db = new dexie.Dexie(type);
    db.version(1).stores({
        [`reservations-${type}`]: 'reservationId'
    });
    await db.table(`reservations-${type}`).clear();
};

const getReservationList =async  (type) => {
    let list=[];
    try{
        const db = new dexie.Dexie(type);
        db.version(1).stores({
            [`reservations-${type}`]: 'reservationId'
        });
        list = await db.table(`reservations-${type}`).toArray();
    }
    catch(e){
        console.log(e);
    }
    return list
}

const upsertReservation = (reservation) => {
    db.table('reservations').put(reservation);
}



export default {
    saveReservationList,
    getReservationList,
    upsertReservation,
    removeAllReservations
}
