
const defaultState = {
  customersList:[],
  loading:false,
  reservationId:0,
  usergroup:0,
  banList:[],
  page:0,
  pageSize:10,
  openCreateBanModal:false,
  openModifyBanModal:false,
  foundBanModal:false,
  banFound:{},
  banToModify:{}
}

export default function reducer(state = defaultState, action) {


  switch (action.type) {
    case "SET_BAN_STATUS":
      return { ...state, ...action.payload }
    default:
     return state
  }
}
