import { isConsumidorFinal } from "../../Funciones/utils"
import moment from "moment"
import store from "../store"

export const payConditions = [
  "Contado",
  "Cuenta corriente",
  "Transferencia Bancaria",
  "Tarjeta de crédito",
  "Tarjeta de débito",
  "vto 5 dias",
  "vto 10 dias",
  "vto 15 dias",
  "vto 20 dias",
  "vto 30 dias",
  "vto 60 dias",
  "vto 90 dias",
  "otra",
]
// export const billTypes=["Factura A",
// "Factura B",
// "Factura C","Nota de credito A","Nota de credito B",
// "Factura no valida en AFIP","Nota de credito no valida en AFIP",
// "Factura C","Factura M","Factura E"]

const defaultState = {
  customer: {},
  paymentsList: [],
  reserva: {},
  loading: false,
  loadingCuitSearch: false,
  itemsList: [],
  tipoPrecio:'camping',
  toggleBilling: false,
  finalConsumerLimit: 46360,
  loadingBill: false,
  payConditions,
  finalConsumerLimitElectronic: 92720,
  billList: [],
  type: "",
  makeBill: false,
  billInfo: {
    typeBill: "Factura B",
    payCondition: "Contado",
    IVACondition: "Consumidor Final",
    dateFrom: moment().format("YYYY-MM-DD"),
    dateTo: moment()
      .add(1, "days")
      .format("YYYY-MM-DD"),
    sendEmail: false,
    total: 0,
    dateBill: moment().format("YYYY-MM-DD"),
  },
}

const getDefaultBillInfo = (typeBill) => ({
  typeBill:typeBill,
  payCondition: "Contado",
  IVACondition: "Consumidor Final",
  dateFrom: moment().format("YYYY-MM-DD"),
  dateTo: moment()
    .add(1, "days")
    .format("YYYY-MM-DD"),
  sendEmail: false,
  total: 0,
  dateBill: moment().format("YYYY-MM-DD"),
})

export default function reducer(state = defaultState, action) {
    const payload=action.payload;
    const billList=state.billList
    const reserva=state.reserva


  switch (action.type) {
    case "SET_DEFAULT_BILLING_INFO":
      return {
        ...state,billInfo:getDefaultBillInfo(state.billTypes?.at(0))
      }
    case "SET_LOADING_BILL":
      return { ...state, loadingBill: payload }
    case "SET_TYPE_BILL":
        return { ...state, billInfo: { ...state.billInfo, typeBill: payload } }
    case "SET_BILLING_INFO":
      if(!payload) return state
      return { ...state, ...payload,billInfo: { ...state.billInfo, typeBill: payload.billTypes?.at(0) } }
    case "SET_RESERVA":
        return {...state,reserva:payload};
    case "SET_LOADING_CUIT_SEARCH":
      return { ...state, loadingCuitSearch: payload }
    case "SET_TOGGLE_BILLING":
      return { ...state, toggleBilling: payload }
    case "SET_PAYMENT_TO_BILL_NOW":
      return { ...state, paymentToBillNow: payload }
    case "SET_PAYMENT_TO_BILL":
      return { ...state, paymentToBill: payload }
    case "SET_MAKE_BILL":
      return { ...state, makeBill: payload }
    case "SET_BILL_LIST":
      return { ...state, billList: payload }
    case "SET_IVA_CONDITION":
      return { ...state, billInfo: { ...state.billInfo, IVACondition: payload } }
    case "SET_CUSTOMER":
      return {
        ...state,
        customer: payload,
        billInfo: {
          ...state.billInfo,
          sendEmail: payload.email ? true : false,
        },
      }
    case "SET_BILL_INFO":
      if (
        payload.typeBill === "Factura C" ||
        payload.typeBill === "Factura B"
      ) {
        const itemsList = state.itemsList.map(item => {
          item.iva = payload.typeBill === "Factura C" ? 0 : 21
          return item
        })
        return {
          ...state,
          itemsList,
          billInfo: { ...state.billInfo, ...payload },
        }
      } else {
        return { ...state, billInfo: { ...state.billInfo, ...payload } }
      }
    case "CHANGE_DESCRIPTION":
      return {
        ...state,
        itemsList: state.itemsList.map(item =>
          item.paymentId === payload.paymentId
            ? { ...item, description: payload.description }
            : item
        ),
      }
    case "SET_PAYMENTS_LIST":
      console.log("paymentsList payload",JSON.stringify(payload))
      const servicesList = reserva.servicesList.filter(service =>
        service.paymentsList.find(payment =>
          payload.find(
            payment2 => payment2.paymentId === payment.paymentId
          )
        )
      )
      const nights =
        billList.length > 0
          ? reserva.servicesList.length > 0
            ? servicesList.reduce((acc, service) => acc + service.quantity, 0)
            : 1
          : 1
      const serviceListWithFromAndTo = addDateFromAndToToServiceList(reserva.servicesList, reserva.checkinEstimated).filter(service => servicesList.find(service2 => service2.serviceId === service.serviceId))

      // select the earliest date from the servicesList
      const dateFrom =serviceListWithFromAndTo.length>0? moment.min(serviceListWithFromAndTo.map(service=>moment(service.dateFrom))).format("YYYY-MM-DD"):moment(reserva.checkinEstimated).format("YYYY-MM-DD");
      // select the latest date from the servicesList
      const dateTo = serviceListWithFromAndTo.length>0?moment.max(serviceListWithFromAndTo.map(service=>moment(service.dateTo))).format("YYYY-MM-DD"):moment(reserva.checkinEstimated).add(1,"days").format("YYYY-MM-DD");
      
      // MANANA VER SI DATE FROM Y DATE TO DEVUELVEN LAS DATES CORRECTAS

      billList.length > 0
          ? calculateDateTo(dateFrom, nights, billList)
          : moment(reserva.checkoutEstimated).format("YYYY-MM-DD")
      const payConditionAux =
        payload.length > 0
          ? choosePayCondition(payload[0].method)
          : "Contado"
      const type = state.reserva.type

      
      //Item list contain description and quantity for each item in the bill
      const itemsList = payload.map((payment, index) => ({
                price: payment.amount,
                paymentId: payment.paymentId,
                description: `Estadia ${type} ${
                  servicesList.find(
                    service =>
                      service.paymentsList.find(
                        payment2 => payment2.paymentId === payment.paymentId
                      )
                  ).description?.toLowerCase()
                }.\nFecha de entrada ${moment(serviceListWithFromAndTo?.find(service=>service.paymentsList.find(payment2=>payment2.paymentId===payment.paymentId)).dateFrom).format("DD/MM/YYYY")}.`,
                quantity: 1,
                iva: state.typeBill == ("Factura C" || "Factura T") ? 0 : 21,
              })  
      )

      return {
        ...state,
        paymentsList: payload,
        itemsList,
        billInfo: {
          ...state.billInfo,
          payCondition: payConditionAux,
          dateFrom,
          dateTo,
          total: payload.reduce(
            (total, payment) => total + parseInt(payment.amount),
            0
          ),
        },
      }
    default:
     return state
  }
}
const choosePayCondition = payFormat => {
  switch (payFormat.toLowerCase()) {
    case "Mercadopago".toLowerCase():
      return "otra"
    case "Tarjeta-Web".toLowerCase():
      return "Tarjeta de crédito"
    case "Tarjeta de débito".toLowerCase():
      return "Tarjeta de débito"
    case "Tarjeta de crédito".toLowerCase():
      return "Tarjeta de crédito"
    case "transferencia bancaria":
      return "Transferencia Bancaria"
    default:
      return "Contado"
  }
}
const addDateFromAndToToServiceList = (serviceList, checkinEstimated) => {
  let serviceListAux = []
  let lastQuantity = 0
  for(let service of serviceList){
    serviceListAux.push({
      ...service,
      dateFrom: moment(checkinEstimated).add(lastQuantity, "days").format("YYYY-MM-DD"),
      dateTo: moment(checkinEstimated).add(lastQuantity + service.quantity, "days").format("YYYY-MM-DD"),
    })
    lastQuantity += service.quantity
  }
  return serviceListAux
}
      
  
const calculateDateFrom = (checkinEstimated, billList) => {
  if (billList.length > 0) {
    const mostRecentBill = billList.sort((a, b) =>
      moment(b.date).diff(moment(a.date))
    )[0]
    const dateFrom = moment(mostRecentBill.dateTo).format("YYYY-MM-DD")
    return dateFrom
  } else {
    return checkinEstimated
  }
}
const calculateDateTo = (from, nights, billList) => {
  if (billList.length > 0) {
    const mostRecentBill = billList.sort((a, b) =>
      moment(b.date).diff(moment(a.date))
    )[0]
    const dateTo = moment(mostRecentBill.dateTo)
      .add(nights, "days")
      .format("YYYY-MM-DD")
    return dateTo
  } else {
    return moment(from)
      .add(1, "days")
      .format("YYYY-MM-DD")
  }
}
