/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const getBillList = `
query GetBillList($from: String!, $limit: Int!) {
  getBillList(from: $from, limit: $limit) {
    documentNumber
    reservationId
    name
    email
    sendEmail
    typeBill
    payCondition
    pointSell
    dateBill
    dateFrom
    dateTo
    listProduct {
      description
      paymentId
      iva
      quantity
      price
    }
    billLink
    specificUsername
    date
    cae
    dateExpireCae
    afipQR
    afipBarCode
    comprobanteNro
    cancelBillNumber
    IVACondition
  }
}
`;

export const billsByReservation = /* GraphQL */ `
  query BillsByReservation($reservationId: String!) {
    billsByReservation(reservationId: $reservationId) {
      documentNumber
      reservationId
      name
      email
      sendEmail
      typeBill
      payCondition
      pointSell
      dateBill
      dateFrom
      dateTo
      listProduct {
        description
        paymentId
        iva
        quantity
        price
      }
      billLink
      cancelBillNumber
      specificUsername
      date
      cae
      dateExpireCae
      afipQR
      comprobanteNro
      afipBarCode
      IVACondition
    }
  }
`;

export const reservationListAsAdmin = /* GraphQL */ `
  query ReservationListAsAdmin($type: String, $username: String!) {
    reservationListAsAdmin(type: $type, username: $username) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        banId
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const reservationList = /* GraphQL */ `
  query ReservationList($type: String!,$from: String,$to: String) {
    reservationList(type: $type,from: $from,to: $to) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        banId
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const reservation = /* GraphQL */ `
  query Reservation($reservationId: String!) {
    reservation(reservationId: $reservationId) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        banId
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const reservationWithOta = /* GraphQL */ `
  query ReservationWithOta($otaId: String!) {
    reservationWithOta(otaId: $otaId) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        banId
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const roomsListAsAdmin = /* GraphQL */ `
  query RoomsListAsAdmin($username: String!) {
    roomsListAsAdmin(username: $username) {
      roomId
      state
      number
      sector
      type
      price
    }
  }
`;
export const roomsList = /* GraphQL */ `
  query RoomsList {
    roomsList {
      roomId
      state
      number
      sector
      type
      price
      orderRank
    }
  }
`;

export const getBannedCustomer = /* GraphQL */ `
  query GetBannedCustomer($dni: String!, $userGroup: String!) {
    getBannedCustomer(dni: $dni, userGroup: $userGroup) {
      banId
      datetime
      reason
      reservationId
      customer {
        customerId
        fullName
        birthdate
        dni
        geo
        phone
        email
      }
      campingName
    }
  }
`;

export const getBanList = /* GraphQL */ `
  query GetBanList($page: Int!, $pageSize: Int!) {
    getBanList(page: $page, pageSize: $pageSize) {
      banId
      datetime
      reason
      specificUsername
      reservationId
      customersList {
        customerId
        fullName
        banId
        birthdate
        dni
        geo
        phone
        email
      }
      username
    }
  }
`;


export const getPaymentList = /* GraphQL */ `
  query GetPaymentList($from: String!, $to: String!, $type: String) {
    getPaymentList(from: $from, to: $to, type: $type) {
      paymentId
      serviceId
      amount
      method
      description
      reservationId
      canceled
      date
      reservationType
      specificUsername
      reservationState
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession {
    getSession
  }
`;
export const getPromotions = /* GraphQL */ `
  query GetPromotions {
    getPromotions
  }
`;
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo {
    getUserInfo
  }
`;
export const getPrices = /* GraphQL */ `
  query GetPrices {
    getPrices
  }
`;

export const getPricesv2 = /* GraphQL */ `
  query GetPricesv2 {
    getPricesv2
  }
`;

export const getMapInfo = /* GraphQL */ `
  query GetMapInfo {
    getMapInfo
  }
`;
export const getCheckoutTime = /* GraphQL */ `
  query GetCheckoutTime {
    getCheckoutTime
  }
`;
export const getSessionAsAdmin = /* GraphQL */ `
  query GetSessionAsAdmin($username: String!) {
    getSessionAsAdmin(username: $username)
  }
`;
export const getCheckoutList = /* GraphQL */ `
  query GetCheckoutList($page: String!, $regularCustomer: Boolean) {
    getCheckoutList(page: $page, regularCustomer: $regularCustomer) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        banId
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const getStatusList = /* GraphQL */ `
  query GetStatusList($page: String!, $status: String!) {
    getStatusList(page: $page, status: $status) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        banId
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const getStatistics = /* GraphQL */ `
  query GetStatistics($date: String!, $type: String!) {
    getStatistics(date: $date, type: $type)
  }
`;
export const getMonthStatistics = /* GraphQL */ `
  query GetMonthStatistics($from: String!, $to: String!, $type: String!) {
    getMonthStatistics(from: $from, to: $to, type: $type)
  }
`;
export const buscarxdni = /* GraphQL */ `
  query Buscarxdni($dni: String) {
    buscarxdni(dni: $dni)
  }
`;
export const getAllPrices = /* GraphQL */ `
  query GetAllPrices($type: String!, $from: String!, $to: String!) {
    getAllPrices(type: $type, from: $from, to: $to) {
      date
      availability
      autoUpdateAvailability
      minStay
      maxStay
      price
    }
  }
`;
export const getAllPricesAsAdmin = /* GraphQL */ `
  query GetAllPricesAsAdmin(
    $type: String!
    $from: String!
    $to: String!
    $username: String!
  ) {
    getAllPricesAsAdmin(
      type: $type
      from: $from
      to: $to
      username: $username
    ) {
      date
      availability
      price
    }
  }
`;
export const getAllCashMovements = /* GraphQL */ `
  query GetAllCashMovements($cashName: String!, $buildNumber: Int!) {
    getAllCashMovements(cashName: $cashName, buildNumber: $buildNumber) {
      cashName
      buildNumber
      date
      amount
      isWithdrawal
      concept
      type
      isMoneyLeft
      specificUsername
      closeCash
    }
  }
`;
export const lastBuild = /* GraphQL */ `
  query LastBuild($cashName: String!) {
    lastBuild(cashName: $cashName) {
      cashName
      buildNumber
      date
      amount
      isWithdrawal
      concept
      type
      isMoneyLeft
      specificUsername
      closeCash
    }
  }
`;
export const search = /* GraphQL */ `
  query Search($search: String!) {
    search(search: $search) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        banId
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const lastMovement = /* GraphQL */ `
  query LastMovement($cashName: String!) {
    lastMovement(cashName: $cashName) {
      cashName
      buildNumber
      date
      amount
      isWithdrawal
      concept
      type
      isMoneyLeft
      specificUsername
      closeCash
    }
  }
`;
export const lastChange = /* GraphQL */ `
  query LastChange {
    lastChange {
      id
    }
  }
`;
export const priceLastChange = /* GraphQL */ `
  query PriceLastChange {
    priceLastChange {
      id
    }
  }
`;
