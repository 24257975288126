import {  addReservation, modifyReservation, addService, addPayment, cancelService, modifyPriceLastChange, createBan, modifyBan, removeBan, modifyUserGroup} from '../../../graphql/mutations';
import { sanitizeReservation, sanitizeService, sanitizePayment, sanitizeCustomer } from "../sanitize";
import { graphqlOperation,API } from 'aws-amplify'; 

/**
* The following function create a new reservation
* Modifies a reservation
* @param {string} reservation - The reservation to modify
* @return {object} The modified reservation or false
*/
export const setNewReservation = async (reservation) => {
    const reservationSanitized = sanitizeReservation(reservation);
    console.log("🚀 ~ file: index.js ~ line 13 ~ setNewReservation ~ reservationSanitized", reservationSanitized)
    try {
        const response = await API.graphql(graphqlOperation(addReservation, {reservation:reservationSanitized}));
        return {id:reservation.id,...response.data.addReservation}
    }
    catch (error) {
        console.log(error);
        return false;
    }

}

/**
* The following function modifies a reservation
* @param {string} reservation - The reservation to modify
* @return {object} The modified reservation or false
*/
export const setModifyReservation = async (reservation) => {
    const reservationSanitized = sanitizeReservation(reservation);
    try {
        const response = await API.graphql(graphqlOperation(modifyReservation, {reservation:reservationSanitized}));
        return {...response.data.modifyReservation,reservationId:reservation.reservationId, id:reservation.id}
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

/**
* The following function sets a service to a reservation, and
* adds a payment to the reservation.
*
* @param {string} reservationId - The id of the reservation
* @param {string} service - The service being added to the reservation
* @param {string} payment - The payment being added to the reservation
* @param {int} addDays - The number of days being added to the reservation
*
* @return {object} - The modified service object
*/
export const setAddService= async (reservationId, service, payment, addDays, id) => {
    const serviceSanitized = sanitizeService(service);
    const paymentSanitized = payment && sanitizePayment(payment);
    try {
        const response = await API.graphql(
            graphqlOperation(addService, {
              reservationId,
              service: serviceSanitized,
              payments: payment ? [paymentSanitized] : [],
              addDays: addDays,
            })
          )
        return {...response.data.addService,reservationId:reservationId, id}
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

/**
* The following function cancel a service
*
* @param {string} serviceId - The service being added to the reservation
* @param {boolean} isCanceled - The payment being added to the reservation
*
* @return {object} - The modified service object
*/
export const setCancelService= async (reservationId, serviceId, isCanceled, id) => {
    try {
        await API.graphql({
            query: cancelService,
            variables: { serviceId, canceled: isCanceled }, //
            authMode: "AMAZON_COGNITO_USER_POOLS",
          })
        return {reservationId:reservationId, id}
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

/**
* The following function sets a payment to a reservation.
*
* @param {string} reservationId - The id of the reservation
* @param {string} serviceId - The service being added to the reservation
* @param {string} newPayment - The payment being added to the reservation
*
* @return {object} - The modified service object
*/
export const setAddPayment= async (reservationId, serviceId, newPayment, id) => {
    const paymentSanitized = sanitizePayment(newPayment);
    try {
        const response = await API.graphql(
            graphqlOperation(addPayment, { serviceId, payment: paymentSanitized })
          )
        return {...response.data.addPayment,reservationId:reservationId, id};
    }
    catch (error) {
        console.log(error);
        return false;
    }
}


/** 
 * The following function sets the modifyPriceLastChange
 * 
 */
export const setModifyPriceLastChange = async () => {
    try {
        const response = await API.graphql(graphqlOperation(modifyPriceLastChange));
        return response.data.modifyPriceLastChange;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

      
/** 
 * The following function creates a new ban
 * 
 */
 export const setNewBan = async (ban) => {
    const customersList = ban.customersList.map(customer => sanitizeCustomer(customer));
    ban.customersList = customersList;
    try {
        const response = await API.graphql(graphqlOperation(createBan, {ban}));
        return response.data.createBan;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

/** 
 * The following function modifies a ban
 * 
 */
    export const setModifyBan = async (ban) => {
    const customersList = ban.customersList.map(customer => sanitizeCustomer(customer));
    ban.customersList = customersList;
    try {
        const response = await API.graphql(graphqlOperation(modifyBan, {ban}));
        return response.data.modifyBan;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

/** 
 * The following function deletes a ban
 * 
 */
export const setDeleteBan = async (banId) => {
    try {
      const response = await API.graphql(graphqlOperation(removeBan, {banId: banId}));
      return response.data.removeBan;
    }
    catch (error) {
      console.log(error);
      return false;
    }
  }

/** 
 * The following function modifies a userGroup
 * 
 */
export const setModifyUserGroup = async (group) => {
    try {
        const response = await API.graphql(graphqlOperation(modifyUserGroup, {group}));
        return true
      }
      catch (error) {
        console.log(error);
        return false;
      }
    }