import React from "react"
import moment from 'moment'
import {Col,Row} from 'reactstrap';
import {DevolverComponentes,ElegirLabel,DevolverComponentesConServicios, ElegirColorSemantic} from "../../../Funciones/utils"
import { Card,Icon } from 'semantic-ui-react'
import { useDispatch,useSelector } from 'react-redux'

//ACTIONS
import setModal from '../../../redux/actions/setModalReserva'
import setReserva from '../../../redux/actions/setReserva'

export const GetCard = ({ingreso,i}) => {
    const dispatch = useDispatch(x=>x)
    const {tipoPrecio} = useSelector(state => state.mainReducer)
    // convert componetns to string
    const components = ingreso.components&& Object.entries(Object.values(JSON.parse(ingreso.components)).reduce((acom,current)=>({...acom,...current}),{})).filter(x=>x.at(1))

   return (
                 <Card color={ElegirColorSemantic(ingreso.state,ingreso.isDeudor)} key={i} onClick={async ()=>{
                         dispatch(setModal(true));
                         dispatch(setReserva(ingreso));

                    
                     }}>
                 {ElegirLabel(ingreso.state,ingreso.nightsDue)}
                    <Card.Content>
                    <Card.Header>{ingreso.customersList[0].fullName}</Card.Header>
                       <Card.Meta style={{color:'black',fontSize:'1.1rem'}}>{components.map(x=>`${x.at(1)} ${x.at(0)}`).join(" ")}</Card.Meta>
                       <Card.Meta>{`${ingreso.roomsList.reduce((acom,current)=>`${current.number} ${acom}`,"")??"Sin Lugar"}`}</Card.Meta>
                       <Card.Meta>{` ${ingreso.vehiclesList.map(x=>`${x.brand??''} ${x.plate??''} ${x.color??''}`).toString()}`}</Card.Meta>
                      <Card.Description>
                        <Row>
                          <Col xs={12}>
                            {moment(ingreso.checkinEstimated).format("DD MMM").split('.').join("")}
                            <Icon name="arrow right"></Icon>
                            {moment(ingreso.checkoutEstimated).format("DD MMM").split('.').join("")}
                            {` (${ingreso.nights} ${ingreso.nights==1?"noche":"noches"})`}
                          </Col>
                        </Row>
                      </Card.Description>
                    </Card.Content>
                </Card>
          )
}



export default GetCard

