
const defaultState = {
  selectedPromotion:{},
  promotionsList:[],
  loading:false,
  page:0,
  pageSize:10,
  openPromotionsNewModal:false,
  openPromotionModifyModal:false

}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "SET_PROMOTION":
      return { ...state, ...action.payload }
    default:
     return state
  }
}
