

import { sanitizeCustomer, sanitizeService, sanitizeVehicle, sanitizeRoom } from './';
export const sanitizeReservation = (reservation) => {
      return {
    reservationId: reservation.reservationId,
    children: reservation.children,
    guests: reservation.guests,
    checkoutEstimated: reservation.checkoutEstimated,
    starredCustomerId: reservation.starredCustomerId,
    checkoutMade: reservation.checkoutMade,
    checkinEstimated: reservation.checkinEstimated,
    checkinMade: reservation.checkinMade,
    created: reservation.created,
    components: reservation.components,
    description: reservation.description,
    customersList: reservation.customersList.map(sanitizeCustomer),
    state: reservation.state,
    way: reservation.way,
    type: reservation.type,
    servicesList: reservation.servicesList.map(sanitizeService),
    roomsList: reservation.roomsList.map(sanitizeRoom),
    vehiclesList: reservation.vehiclesList.map(sanitizeVehicle),
    otaId: reservation.otaId,
    regularCustomer: reservation.regularCustomer,
  };
}