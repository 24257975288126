
import { sanitizePayment } from './';
export const sanitizeService = (service) => {
  const { serviceId, cost, quantity, date, name, isPaid, canceled, paymentsList, description, components } = service;
  return {
    serviceId,
    cost,
    quantity,
    date,
    name,
    isPaid,
    canceled,
    paymentsList: paymentsList.map(sanitizePayment),
    description,
    components,
  };
}