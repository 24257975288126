
const networkFirst = async (key, fn) =>
{
  try
  {
    const response = await fn();
    if (response)
    {
      localStorage.setItem(key, JSON.stringify(response));
      return response;
    }
    return response;
  }
  catch (error)
  {
    const cachedData = localStorage.getItem(key);
    if (cachedData)
    {
      return JSON.parse(cachedData);
    }
    throw error;
  }
}

export default networkFirst;