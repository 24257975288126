export const defaultState = {
  newReservationsQueue:[],
  modifyReservationsQueue:[],
  servicesQueue:[],
  cancelServicesQueue:[],
  paymentsQueue:[],
  vehiclesQueue:[],
}

export default function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "INIT_ALL_QUEUES":
      return { ...state, ...payload }
    case "ADD_RESERVATION_TO_QUEUE":
      return { ...state, newReservationsQueue: [...state.newReservationsQueue,payload] }
    case "ADD_MODIFY_RESERVATION_TO_QUEUE":
      return { ...state, modifyReservationsQueue: [...state.modifyReservationsQueue,payload] }
    case "ADD_SERVICE_TO_QUEUE":
      return { ...state, servicesQueue: [...state.servicesQueue,payload] }
    case "ADD_CANCEL_SERVICE_TO_QUEUE":
      return { ...state, cancelServicesQueue: [...state.cancelServicesQueue,payload] }
    case "ADD_PAYMENT_TO_QUEUE":
      return { ...state, paymentsQueue: [...state.paymentsQueue,payload] }
    case "ADD_VEHICLE_TO_QUEUE":
      return { ...state, vehiclesQueue: [...state.vehiclesQueue,payload] }
    case "REMOVE_RESERVATION_FROM_QUEUE":
      return { ...state, newReservationsQueue: state.newReservationsQueue.filter(x=>x.reservationId!=payload) }
    case "REMOVE_MODIFY_RESERVATION_FROM_QUEUE":
      return { ...state, modifyReservationsQueue: state.modifyReservationsQueue.filter(x=>x.reservationId!=payload) }
    case "REMOVE_SERVICE_FROM_QUEUE":
      return { ...state, servicesQueue: state.servicesQueue.filter(x=>x.serviceId!=payload) }
    case "REMOVE_VEHICLE_FROM_QUEUE":
      return { ...state, vehiclesQueue: state.vehiclesQueue.filter(x=>x.vehicleId!=payload) }
    case "REMOVE_ALL":
      return { ...state, newReservationsQueue: [],modifyReservationsQueue:[],servicesQueue:[],vehiclesQueue:[] }
    case "SET_RESERVATION_QUEUE":
      return { ...state, newReservationsQueue: payload }
    case "SET_MODIFY_RESERVATION_QUEUE":
      return { ...state, modifyReservationsQueue: payload }
    case "SET_SERVICE_QUEUE":
      return { ...state, servicesQueue: payload }
    case "SET_CANCEL_SERVICE_QUEUE":
      return { ...state, cancelServicesQueue: payload }
    case "SET_PAYMENT_QUEUE":
      return { ...state, paymentsQueue: payload }
    case "SET_VEHICLE_QUEUE":
      return { ...state, vehiclesQueue: payload }
    default:
     return state
  }
}
