const defaultState={
    togglePrinter:false,
    isCopyingTicket:false,
};

export default function reducer(state=defaultState,{type,payload}){
    switch (type){
        case "INIT_PRINTER":
            return {...state,printer:{...payload}};
        case "SET_PRINTER_CONNECTED":
            return {...state,printer:{...state.printer,isConnected:payload}};
        case "SET_PRINTER_PRINTING":
            return {...state,printer:{...state.printer,isPrinting:payload}};
        case "SET_PRINTER_INSTANCE":
            return {...state,printer:{...state.printer,instance:payload}};
        case "SET_PRINT_TICKET":
            return {...state,printer:{...state.printer,printTicket:payload}};
        case "SET_PRINT_CHECKIN":
            return {...state,printer:{...state.printer,printCheckin:payload}};
        case "SET_PRINT_CHECKOUT":
            return {...state,printer:{...state.printer,printCheckout:payload}};
        case "SET_PRINTER_CONNECTING":
            return {...state,printer:{...state.printer,isConnecting:payload}};
        case "SET_TOGGLE_PRINTER":
            return {...state,togglePrinter:payload};
        case "IS_COPYING_TICKET":
            return {...state,isCopyingTicket:payload};
        default:
            return state
    }
}