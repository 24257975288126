/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addReservationAsAdmin = /* GraphQL */ `
  mutation AddReservationAsAdmin(
    $reservation: ReservationInput!
    $username: String!
  ) {
    addReservationAsAdmin(reservation: $reservation, username: $username) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const addReservation = /* GraphQL */ `
  mutation AddReservation($reservation: ReservationInput!) {
    addReservation(reservation: $reservation) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const addPreReservation = /* GraphQL */ `
  mutation AddPreReservation(
    $reservation: ReservationInput!
    $camping: String!
  ) {
    addPreReservation(reservation: $reservation, camping: $camping) {
      reservationId
      children
      guests
      checkoutEstimated
      starredCustomerId
      checkoutMade
      checkinEstimated
      checkinMade
      created
      components
      description
      customersList {
        customerId
        fullName
        birthdate
        dni
        geo
        phone
        email
      }
      state
      way
      type
      servicesList {
        serviceId
        cost
        quantity
        date
        name
        isPaid
        canceled
        paymentsList {
          paymentId
          amount
          date
          isRefund
          method
          specificUsername
        }
        description
        components
      }
      roomsList{
        roomId
        state
        number
        sector
        type
        price
      }
      vehiclesList {
        vehicleId
        plate
        brand
        type
        color
      }
      otaId
      extrasList {
        extraId
        amount
        name
      }
      regularCustomer
    }
  }
`;
export const addService = /* GraphQL */ `
  mutation AddService(
    $reservationId: String!
    $service: ServiceInput
    $payments: [PaymentInput]
    $addDays: Int!
  ) {
    addService(
      reservationId: $reservationId
      service: $service
      payments: $payments
      addDays: $addDays
    ) {
      serviceId
      cost
      quantity
      date
      name
      isPaid
      canceled
      paymentsList {
        paymentId
        amount
        date
        isRefund
        method
        specificUsername
      }
      description
      components
    }
  }
`;
export const addPayment = /* GraphQL */ `
  mutation AddPayment($serviceId: String!, $payment: PaymentInput) {
    addPayment(serviceId: $serviceId, payment: $payment) {
      paymentId
      amount
      date
      isRefund
      method
      specificUsername
    }
  }
`;
export const modifyRoom = /* GraphQL */ `
  mutation ModifyRoom($room: RoomInput!) {
    modifyRoom(room: $room) {
      roomId
      state
      number
      sector
      type
    }
  }
`;

export const upsertPromotion = /* GraphQL */ `
  mutation UpsertPromotion($promotion: AWSJSON!, $datetime: String!) {
    upsertPromotion(promotion: $promotion, datetime: $datetime)
  }
`;

export const deletePromotion = /* GraphQL */ `
  mutation DeletePromotion($datetime: String!) {
    removePromotion(datetime: $datetime)
  }
`;

export const addRoom = /* GraphQL */ `
  mutation AddRoom($room: RoomInput!) {
    addRoom(room: $room) {
      roomId
      state
      number
      sector
      type
    }
  }
`;
export const modifyReservation = /* GraphQL */ `
  mutation ModifyReservation($reservation: ReservationInput!) {
    modifyReservation(reservation: $reservation)
  }
`;
export const modifyPrices = /* GraphQL */ `
  mutation ModifyPrices($prices: AWSJSON!) {
    modifyPrices(prices: $prices)
  }
`;
export const modifyVehicles = /* GraphQL */ `
  mutation ModifyVehicles(
    $reservationId: String!
    $vehiclesList: [VehicleInput!]!
  ) {
    modifyVehicles(reservationId: $reservationId, vehiclesList: $vehiclesList) {
      vehicleId
      plate
      brand
      type
      color
    }
  }
`;

export const createBan = /* GraphQL */ `
  mutation CreateBan($ban: BanInput) {
    createBan(ban: $ban)
  }
`;

export const modifyBan = /* GraphQL */ `
  mutation ModifyBan($ban: BanInput) {
    modifyBan(ban: $ban)
  }
`;

export const removeBan = /* GraphQL */ `
  mutation RemoveBan($banId: String!) {
    removeBan(banId: $banId)
  }
`;

export const modifyUserGroup = /* GraphQL */ `
  mutation ModifyUserGroup($group: String!) {
    modifyUserGroup(group: $group)
  }
`;

export const makeBill = /* GraphQL */ `
  mutation MakeBill(
    $userToken: String!
    $apiKey: String!
    $apiToken: String!
    $bill: BillInput
  ) {
    makeBill(
      userToken: $userToken
      apiKey: $apiKey
      apiToken: $apiToken
      bill: $bill
    )
  }
`;
export const modifyPriceLastChange = /* GraphQL */ `
  mutation ModifyPriceLastChange($type: String) {
    modifyPriceLastChange(type: $type) {
      id
    }
  }
`;

export const addPrice = /* GraphQL */ `
  mutation AddPrice(
    $type: String!
    $date: String!
    $availability: Int
    $autoUpdateAvailability: Int
    $minStay: Int
    $maxStay: Int
    $price: AWSJSON
  ) {
    addPrice(
      type: $type
      date: $date
      availability: $availability
      autoUpdateAvailability: $autoUpdateAvailability
      minStay: $minStay
      maxStay: $maxStay
      price: $price
    ) {
      date
      availability
      autoUpdateAvailability
      minStay
      maxStay
      price
    }
  }
`;
export const addPriceAsAdmin = /* GraphQL */ `
  mutation AddPriceAsAdmin(
    $username: String!
    $type: String!
    $date: String!
    $availability: Int
    $price: AWSJSON
  ) {
    addPriceAsAdmin(
      username: $username
      type: $type
      date: $date
      availability: $availability
      price: $price
    ) {
      date
      availability
      price
    }
  }
`;
export const addCashMovement = /* GraphQL */ `
  mutation AddCashMovement(
    $cashName: String!
    $amount: Int!
    $isWithdrawal: Boolean!
    $concept: String
    $buildNumber: Int!
    $type: String
    $isMoneyLeft: Boolean
    $closeCash: Boolean
  ) {
    addCashMovement(
      cashName: $cashName
      amount: $amount
      isWithdrawal: $isWithdrawal
      concept: $concept
      buildNumber: $buildNumber
      type: $type
      isMoneyLeft: $isMoneyLeft
      closeCash: $closeCash
    ) {
      cashName
      buildNumber
      date
      amount
      isWithdrawal
      concept
      type
      isMoneyLeft
      specificUsername
      closeCash
    }
  }
`;
export const cancelService = /* GraphQL */ `
  mutation CancelService($serviceId: String!, $canceled: Boolean!) {
    cancelService(serviceId: $serviceId, canceled: $canceled)
  }
`;
