
export const sanitizeCustomer = (customer) => {
  const { customerId, fullName, birthdate, dni, geo, phone, email } = customer;
  return {
    customerId,
    fullName,
    birthdate,
    dni,
    geo,
    phone,
    email,
  };
}

