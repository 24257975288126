const dexie = require("dexie");
const db = new dexie.Dexie("lastChange");
db.version(1).stores({
    lastChange: "value",
  });

const saveLastChange = async (lastChange) => {
  try {
    await db.table(`lastChange`).put({ value: lastChange });
  } catch (error) {
    console.log(error);
  }
};

const getLastChange = async () => {
  let lastChange={};
  try {
    lastChange = await db.table(`lastChange`).toCollection().first();
    return lastChange.value;
  } catch (error) {
    console.log(error);
  }
  return null;
};


export default {
    saveLastChange,
    getLastChange
};
