import store from '../../../redux/store';
const dexie = require('dexie');
const reservationQueue = new dexie.Dexie("reservationQueue");

const reservationTable = 'reservationQueue';
reservationQueue.version(2).stores({
    reservationQueue: '++id'
});


const saveReservation = async (reservation) => {
    const id = await reservationQueue.table(reservationTable).put({...reservation, queueType: 'newReservationsQueue' });    ;
    await store.dispatch({ type: 'ADD_RESERVATION_TO_QUEUE', payload: {id, ...reservation} });
    return reservation
};

// check if reservation exists in queue and update it
const modifyReservation = async (reservation) => {
    // find reservation in queue
    const reservationInQueue = await reservationQueue.table(reservationTable).toArray();
    const reservationInQueueIndex = reservationInQueue.filter(x=>x.queueType == 'modifyReservationsQueue').find(x => x.reservationId === reservation.reservationId)?.id;
    if(reservationInQueueIndex){
        const id = await reservationQueue.table(reservationTable).put({id:reservationInQueueIndex,...reservation, queueType: 'modifyReservationsQueue' });
        await store.dispatch({ type: 'REMOVE_MODIFY_RESERVATION_FROM_QUEUE', payload: reservation.reservationId });
        await store.dispatch({ type: 'ADD_MODIFY_RESERVATION_TO_QUEUE', payload: {id, ...reservation} });
    }
    else{
        const id =  await reservationQueue.table(reservationTable).put({...reservation, queueType: 'modifyReservationsQueue' });
        await store.dispatch({ type: 'ADD_MODIFY_RESERVATION_TO_QUEUE', payload: {id, ...reservation} });
    }

    return reservation
};

const saveService = async (service) => {
    const id =  await reservationQueue.table(reservationTable).put({...service, queueType: 'servicesQueue' });
    await store.dispatch({ type: 'ADD_SERVICE_TO_QUEUE', payload: {id, ...service} });
    return service
};

const saveCancelService = async (service) => {
    const id =  await reservationQueue.table(reservationTable).put({...service, queueType: 'cancelServiceQueue' });
    await store.dispatch({ type: 'ADD_CANCEL_SERVICE_TO_QUEUE', payload: {id, ...service} });
    return service
};

const saveVehicle = async (vehicle) => {
    const id = await reservationQueue.table(reservationTable).put({...vehicle, queueType: 'vehiclesQueue' });
    await store.dispatch({ type: 'ADD_VEHICLE_TO_QUEUE', payload: {id, ...vehicle} });
    return vehicle
};

const savePayment = async (payment) => {
    const id = await reservationQueue.table(reservationTable).put({...payment, queueType: 'paymentsQueue' });
    await store.dispatch({ type: 'ADD_PAYMENT_TO_QUEUE', payload: {id, ...payment} });
    return payment
};

const initAllQueues = async () => {
    const queues = await reservationQueue.table(reservationTable).toArray();
    const newReservationsQueue = queues.filter(x => x.queueType === 'newReservationsQueue');
    const modifyReservationsQueue = queues.filter(x => x.queueType === 'modifyReservationsQueue');
    const servicesQueue = queues.filter(x => x.queueType === 'servicesQueue');
    const cancelServicesQueue = queues.filter(x => x.queueType === 'cancelServiceQueue');
    const paymentsQueue = queues.filter(x => x.queueType === 'paymentsQueue');
    const vehiclesQueue = queues.filter(x => x.queueType === 'vehiclesQueue');
    await store.dispatch({ type: 'INIT_ALL_QUEUES', payload: { newReservationsQueue, modifyReservationsQueue, servicesQueue, cancelServicesQueue, paymentsQueue, vehiclesQueue } });
}

const deleteFromQueue = async (idList) => {
    await reservationQueue.table(reservationTable).bulkDelete(idList);
    return idList
};

export default {
    saveReservation,
    modifyReservation,
    saveService,
    saveVehicle,
    savePayment,
    saveCancelService,
    initAllQueues,
    deleteFromQueue
};