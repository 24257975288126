import { API, graphqlOperation,Auth } from "aws-amplify"
import {
  reservationList,
  lastChange,
  priceLastChange,
  getBannedCustomer,
  getBanList
} from "../../../graphql/queries"

import {reservationCache, lastChangeCache, lastPriceChangeCache}  from "../cache"

export const GetReservationList = async (typeList) => {
  try {
    const promises = typeList.map((type) =>
      API.graphql({
        query: reservationList,
        variables: { type: type },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
    );

    const responses = await Promise.all(promises);
    const reservations = responses.map((response) => response.data.reservationList).flat();

    if (reservations.length > 0) {
      await reservationCache.removeAllReservations(typeList, reservations);
      await reservationCache.saveReservationList(typeList, reservations);
    }

    return reservations;
  } catch (error) {
    const hasReservation = await reservationCache.getReservationList(typeList);

    if (hasReservation.length > 0) {
      return hasReservation;
    }

    return [];
  }
};

export const GetLastChange = async ()=>{
    try {
      const response = await API.graphql({
        query: lastChange,
        authMode: "AMAZON_COGNITO_USER_POOLS"
      })
      await lastChangeCache.saveLastChange(response.data.lastChange.id)
      return response.data.lastChange.id
    } catch (error) {
      const hasLastChange = await lastChangeCache.getLastChange();
      if(hasLastChange){
        return hasLastChange;
      }
      return null;      
      }
  }

export const GetLastPriceChange = async ()=>{
    try {
      const response = await API.graphql({
        query: priceLastChange,
        authMode: "AMAZON_COGNITO_USER_POOLS"
      })
      await lastPriceChangeCache.saveLastPriceChange(response.data.priceLastChange.id)
      return response.data.priceLastChange.id
    } catch (error) {
      const hasLastChange = await lastPriceChangeCache.getLastPriceChange();
      if(hasLastChange){
        return hasLastChange;
      }
      return null;      
      }
  }

  export const GetBannedCustomer= async ({dni, userGroup})=>{
    try {
      const response = await API.graphql({
        query: getBannedCustomer,
        variables: { dni: dni, userGroup: userGroup },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      })
      return response.data.getBannedCustomer
    } catch (error) {
      return null;      
      }
  }

  export const GetBanList = async ({page, pageSize})=>{
    try {
      const response = await API.graphql({
        query: getBanList,
        variables: { page, pageSize },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      })
      
      return response.data.getBanList
    } catch (error) {
      return [];      
      }
  }

