import moment from 'moment';
import React from 'react';
import QRCode from 'qrcode'
import { MejorarIngreso, DevolverComponentesConServicios, DevolverComponentes } from './utils';

const spaceFixed = () => (
 `







`)


//Checkin signature version with html
export const CheckinSignature = (ingreso) => (
    `
    <html>
    <head>
    <style>
    body{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
    }
    </style>
    </head>
    <body>
    <div style="width:100%;">
    <div style="width:100%;text-align:center;">
    <h1>CHECKIN</h1>
    </div>
    <div style="width:100%;text-align:center;">
    <h2>${moment().format('DD/MM/YYYY HH:mm')}</h2>
    </div>
    <div style="width:100%;text-align:center;">
    <h3>PERSONAS</h3>
    </div>
    <div style="width:100%">
    <table style="width:100%;">
    <tr>
    <th>Nombre</th>
    <th>DNI</th>
    <th>Edad</th>
    </tr>
    ${ingreso.customersList.map(x=>(
    `<tr>
    <td>${x.fullName}</td>
    <td>${x.dni==null?"-":x.dni}</td>
    <td>${(x.age==0||!x.age)?"-":x.age}</td>
    </tr>`
    )).join('\n')}
    </table>
    </div>
    ${ingreso.vehiclesList.length>0?`
    <div style="width:100%;text-align:center;">
    <h3>VEHICULOS</h3>
    </div>
    <div style="width:100%;text-align:center;">
    <table style="width:100%;">
    <tr>
    <th>PATENTE</th>
    <th>TIPO</th>
    <th>COLOR</th>
    </tr>
    ${ingreso.vehiclesList.map(x=>(
    `<tr>
    <td>${x.plate}</td>
    <td>${x.brand}</td>
    <td>${x.color}</td>
    </tr>`
    )).join('\n')}
    </table>
    </div>
    `:` <div style="width:100%;text-align:center;">SIN VEHICULOS</div>`}

    <div style="width:100%;text-align:center;">
    <h3>Lugar: ${ingreso.roomsList.map(x=>x.number).join(", ")}</h3>
    </div>
    <div style="width:100%;text-align:center;">
    <h3>Cantidad de noches: ${MejorarIngreso(ingreso).nights}</h3>
    </div>
    <div style="width:100%;text-align:center;">
    <h3>Firma__________________________________________</h3>
    </div>
    <div style="width:100%;text-align:center;">
    <h3>Aclaracion_____________________________________</h3>
    </div>
    <br/>
    </div>
    </body>
    </html>
    `)

//Checkin signature version with html
export const CheckoutSignature = (ingreso,customer) => (    `
    <html>
    <head>
    <style>
    body{
        font-family: Arial, Helvetica, sans-serif;
        text-align:center;
        font-size: 12px;
    }
    tr{
        text-align:center;
    }
    </style>
    </head>
    <body>
    <div style="width:100%;">
    <div style="width:100%">
    <table style="width:100%;">
    <tr>
    <th>Nombre</th>
    <th>DNI</th>
    </tr>
    <tr>
    <td>${customer?.fullName}</td>
    <td>${customer?.dni||"-"}</td>
    </tr>
    </table>
    </div>
    ${ingreso.vehiclesList.length>0?`
    <div style="width:100%;text-align:center;">
    </div>
    <div style="width:100%;text-align:center;">
    <table style="width:100%;">
    <tr>
    <th>Patente</th>
    <th>Tipo</th>
    <th>Color</th>
    </tr>
    ${ingreso.vehiclesList.map(x=>(
    `<tr>
    <td>${x.plate}</td>
    <td>${x.brand}</td>
    <td>${x.color}</td>
    </tr>`
    )).join('\n')}
    </table>
    </div>
    `:``}

    <div style="width:100%;text-align:center;">
        ${ingreso.roomsList.length>0?
        `<h3>Lugar: ${ingreso.roomsList.map(x=>x.number).join(", ")}</h3>`:
        ``}
    </div>
    <div style="width:100%;text-align:center;">
    <h5>Por favor no retirarse definitivamente del complejo sin antes avisar su partida</h5>
    </div>
    </div>
    </body>
    </html>
    `)

//Html for the bill
export const TicketFacturaB= (billingInfo,numeroFactura,fechaEmision,formaPago,listaProductos,CAE,vencimientoCAE,afipQR,cliente) => 
`
<!DOCTYPE html>
<html lang="en">

<head>
    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: 'PT Sans', sans-serif;
        }

        @page {
            size: 2.8in 11in;
            margin-top: 0cm;
            margin-left: 0cm;
            margin-right: 0cm;
        }

        table {
            width: 100%;
        }

        tr {
            width: 100%;

        }

        h1 {
            text-align: center;
            vertical-align: middle;
        }

        #logo {
            width: 60%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            padding: 5px;
            margin: 2px;
            display: block;
            margin: 0 auto;
        }

        header {
            width: 100%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            vertical-align: middle;
        }

        .items thead {
            text-align: center;
        }

        .center-align {
            text-align: center;
        }

        .bill-details td {
            font-size: 12px;
        }

        .receipt {
            font-size: medium;
            font-weight: bold;
        }

        .items .heading {
            font-size: 12.5px;
            text-transform: uppercase;
            border-top:1px solid black;
            margin-bottom: 4px;
            border-bottom: 1px solid black;
            vertical-align: middle;
        }

        .items thead tr th:first-child,
        .items tbody tr td:first-child {
            width: 47%;
            min-width: 47%;
            max-width: 47%;
            word-break: break-all;
            text-align: left;
        }

        .items td {
            font-size: 12px;
            text-align: right;
            vertical-align: bottom;
        }

        .price::before {
            font-family: Arial;
            text-align: right;
        }

        .sum-up {
            text-align: right !important;
        }
        .total {
            font-size: 13px;
            border-top:1px dashed black !important;
            border-bottom:1px dashed black !important;
        }
        .total.text, .total.price {
            text-align: right;
        }
        .total.price {
            font-size: 16px;
        }
        .line {
            border-top:1px solid black !important;
        }
        .heading.amount {
            width: 10%;
        }
        .heading.qty {
            width: 5%
        }
        p {
            padding: 1px;
            margin: 0;
        }
        section, footer {
            font-size: 12px;
        }
        .qr-code{
            width:100px;
            height:auto;
            margin:auto;
          }
    </style>
</head>

<body>
    <header>
        <span class="receipt">${billingInfo.fantasyName}</span>
    </header>
    <br/>
    <section>
        <p>
            <span>${billingInfo.razonSocial}</span>
        </p>
        <p>
            CUIT : <span>${billingInfo.CUIT}</span>
        </p>
        <p>
            Ing. Brutos : <span>${billingInfo.ingresosBrutos}</span>
        </p>
        <p>
            Domicilio : <span>${billingInfo.address}</span>
        </p>
        <p>
            Inicio de actividades : <span>${billingInfo.startActivity}</span>
        </p>
        <p>
            ${billingInfo.IVACondition}
        </p>

</section>
    <table class="bill-details">
        <tbody>
            <tr>
                <td>Fecha : <span>${moment().format('DD/MM/YYYY HH:mm')}</span></td>
                <td>Nro : <span>${numeroFactura}</span></td>
            </tr>
            <tr>
                <th class="center-align" colspan="2"><span class="receipt">Factura B (Cod. 06)</span></th>
            </tr>
        </tbody>
    </table>
    <section>
    
    ${cliente.name!=`N/A`?`<p>
    A  <span>${`${cliente.name} ${cliente.documentNumber!=0?cliente.documentNumber:""}`}</span>
    </p>`:""}   


</section>
 
    <table class="items">
        <thead>
            <tr>
                <th class="heading name">Descripcion</th>
                <th class="heading amount">Precio</th>
            </tr>
        </thead>
       
        <tbody>
        ${listaProductos.map(producto => {
            return `<tr>
            <td>${producto.description}</td>
            <td class="price">${producto.price}</td>
            </tr>`
            }).join('')}
            <tr>
                <td colspan="1" class="sum-up line">Subtotal</td>
                <td class="line price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</td>
            </tr>
            <tr>
                <th colspan="1" class="total text">Total</th>
                <th class="total price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</th>
            </tr>


        </tbody>
    </table>
    <section>
        <p>
            Forma de pago : <span>${formaPago}</span>
        </p>
        <p>
            Fecha de emision : <span>${moment(fechaEmision).format("DD/MM/YYYY")}</span>
        </p>
        <p>
            CAE : <span>${CAE}</span>
        </p>
        <p>
            Vencimiento CAE : <span>${moment(vencimientoCAE).format('DD/MM/YYYY')}</span>
        </p>
        ${afipQR?`
        <div class="qr-code">
            <img width="100px" src="${afipQR}"/>
        </div>`:''}
    </section>
    <footer style="text-align:center">
        <p>¡Gracias por su visita!</p>
    </footer>
</body>

</html>`

//Html for the bill
export const TicketFacturaC = (billingInfo,numeroFactura,fechaEmision,formaPago,listaProductos,CAE,vencimientoCAE,afipQR,cliente) => 
`
<!DOCTYPE html>
<html lang="en">

<head>
    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: 'PT Sans', sans-serif;
        }

        @page {
            size: 2.8in 11in;
            margin-top: 0cm;
            margin-left: 0cm;
            margin-right: 0cm;
        }

        table {
            width: 100%;
        }

        tr {
            width: 100%;

        }

        h1 {
            text-align: center;
            vertical-align: middle;
        }

        #logo {
            width: 60%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            padding: 5px;
            margin: 2px;
            display: block;
            margin: 0 auto;
        }

        header {
            width: 100%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            vertical-align: middle;
        }

        .items thead {
            text-align: center;
        }

        .center-align {
            text-align: center;
        }

        .bill-details td {
            font-size: 12px;
        }

        .receipt {
            font-size: medium;
            font-weight: bold;
        }

        .items .heading {
            font-size: 12.5px;
            text-transform: uppercase;
            border-top:1px solid black;
            margin-bottom: 4px;
            border-bottom: 1px solid black;
            vertical-align: middle;
        }

        .items thead tr th:first-child,
        .items tbody tr td:first-child {
            width: 47%;
            min-width: 47%;
            max-width: 47%;
            word-break: break-all;
            text-align: left;
        }

        .items td {
            font-size: 12px;
            text-align: right;
            vertical-align: bottom;
        }

        .price::before {
            font-family: Arial;
            text-align: right;
        }

        .sum-up {
            text-align: right !important;
        }
        .total {
            font-size: 13px;
            border-top:1px dashed black !important;
            border-bottom:1px dashed black !important;
        }
        .total.text, .total.price {
            text-align: right;
        }
        .total.price {
            font-size: 16px;
        }
        .line {
            border-top:1px solid black !important;
        }
        .heading.amount {
            width: 10%;
        }
        .heading.qty {
            width: 5%
        }
        p {
            padding: 1px;
            margin: 0;
        }
        section, footer {
            font-size: 12px;
        }
        .qr-code{
            width:100px;
            height:auto;
            margin:auto;
          }
    </style>
</head>

<body>
    <header>
        <span class="receipt">${billingInfo.fantasyName}</span>
    </header>
    <br/>
    <section>
        <p>
            <span>${billingInfo.razonSocial}</span>
        </p>
        <p>
            CUIT : <span>${billingInfo.CUIT}</span>
        </p>
        <p>
            Ing. Brutos : <span>${billingInfo.ingresosBrutos}</span>
        </p>
        <p>
            Domicilio : <span>${billingInfo.address}</span>
        </p>
        <p>
            Inicio de actividades : <span>${billingInfo.startActivity}</span>
        </p>
        <p>
            ${billingInfo.IVACondition}
        </p>

</section>
    <table class="bill-details">
        <tbody>
            <tr>
                <td>Fecha : <span>${moment().format('DD/MM/YYYY HH:mm')}</span></td>
                <td>Nro : <span>${numeroFactura}</span></td>
            </tr>
            <tr>
                <th class="center-align" colspan="2"><span class="receipt">Factura C (Cod. 011)</span></th>
            </tr>
        </tbody>
    </table>
    <section>
    
    ${cliente.name!=`N/A`?`<p>
    A  <span>${`${cliente.name} ${cliente.documentNumber!=0?cliente.documentNumber:""}`}</span>
    </p>`:""}   


</section>
 
    <table class="items">
        <thead>
            <tr>
                <th class="heading name">Descripcion</th>
                <th class="heading amount">Precio</th>
            </tr>
        </thead>
       
        <tbody>
        ${listaProductos.map(producto => {
            return `<tr>
            <td>${producto.description}</td>
            <td class="price">${producto.price}</td>
            </tr>`
            }).join('')}
            <tr>
                <td colspan="1" class="sum-up line">Subtotal</td>
                <td class="line price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</td>
            </tr>
            <tr>
                <th colspan="1" class="total text">Total</th>
                <th class="total price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</th>
            </tr>


        </tbody>
    </table>
    <section>
        <p>
            Forma de pago : <span>${formaPago}</span>
        </p>
        <p>
            Fecha de emision : <span>${moment(fechaEmision).format("DD/MM/YYYY")}</span>
        </p>
        <p>
            CAE : <span>${CAE}</span>
        </p>
        <p>
            Vencimiento CAE : <span>${moment(vencimientoCAE).format('DD/MM/YYYY')}</span>
        </p>
        ${afipQR?`
        <div class="qr-code">
            <img width="100px" src="${afipQR}"/>
        </div>`:''}
    </section>
    <footer style="text-align:center">
        <p>¡Gracias por su visita!</p>
    </footer>
</body>

</html>`

export const TicketFacturaA= (billingInfo,numeroFactura,fechaEmision,formaPago,IVACondition,listaProductos,CAE,vencimientoCAE,afipQR,cliente) => 
{
    const total = listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)
    const subtotal = parseInt(total/1.21)
    const iva = total - subtotal

    return`
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <style>
            body {
                margin: 0;
                padding: 0;
                font-family: 'PT Sans', sans-serif;
            }

            @page {
                size: 2.8in 11in;
                margin-top: 0cm;
                margin-left: 0cm;
                margin-right: 0cm;
            }

            table {
                width: 100%;
            }

            tr {
                width: 100%;

            }

            h1 {
                text-align: center;
                vertical-align: middle;
            }

            #logo {
                width: 60%;
                text-align: center;
                -webkit-align-content: center;
                align-content: center;
                padding: 5px;
                margin: 2px;
                display: block;
                margin: 0 auto;
            }

            header {
                width: 100%;
                text-align: center;
                -webkit-align-content: center;
                align-content: center;
                vertical-align: middle;
            }

            .items thead {
                text-align: center;
            }

            .center-align {
                text-align: center;
            }

            .bill-details td {
                font-size: 12px;
            }

            .receipt {
                font-size: medium;
                font-weight: bold;
            }

            .items .heading {
                font-size: 12.5px;
                text-transform: uppercase;
                border-top:1px solid black;
                margin-bottom: 4px;
                border-bottom: 1px solid black;
                vertical-align: middle;
            }

            .items thead tr th:first-child,
            .items tbody tr td:first-child {
                width: 47%;
                min-width: 47%;
                max-width: 47%;
                word-break: break-all;
                text-align: left;
            }

            .items td {
                font-size: 12px;
                text-align: right;
                vertical-align: bottom;
            }

            .price::before {
                font-family: Arial;
                text-align: right;
            }

            .sum-up {
                text-align: right !important;
            }
            .total {
                font-size: 13px;
                border-top:1px dashed black !important;
                border-bottom:1px dashed black !important;
            }
            .total.text, .total.price {
                text-align: right;
            }
            .total.price {
                font-size: 16px;
            }
            .line {
                border-top:1px solid black !important;
            }
            .heading.amount {
                width: 10%;
            }
            .heading.qty {
                width: 5%
            }
            p {
                padding: 1px;
                margin: 0;
            }
            section, footer {
                font-size: 12px;
            }
            .qr-code{
                width:100px;
                height:auto;
                margin:auto;
            }
        </style>
    </head>

    <body>
        <header>
            <span class="receipt">${billingInfo.fantasyName}</span>
        </header>
        <br/>
        <section>
            <p>
                <span>${billingInfo.razonSocial}</span>
            </p>
            <p>
                CUIT : <span>${billingInfo.CUIT}</span>
            </p>
            <p>
                Ing. Brutos : <span>${billingInfo.ingresosBrutos}</span>
            </p>
            <p>
                Domicilio : <span>${billingInfo.address}</span>
            </p>
            <p>
                Inicio de actividades : <span>${billingInfo.startActivity}</span>
            </p>
            <p>
                IVA RESPONSABLE INSCRIPTO
            </p>

    </section>
        <table class="bill-details">
            <tbody>
                <tr>
                    <td>Fecha : <span>${moment().format('DD/MM/YYYY HH:mm')}</span></td>
                    <td>Nro : <span>${numeroFactura}</span></td>
                </tr>
                <tr>
                    <th class="center-align" colspan="2"><span class="receipt">Factura A (Cod. 01)</span></th>
                </tr>
            </tbody>
        </table>
        <section>
        
        <p>
            <span>${cliente.name}</span>
        </p>
        
        <p>
            CUIT : <span>${cliente.documentNumber}</span>
        </p>

        <p>
        <span>${IVACondition}</span>
        </p>


    </section>
        <table class="items">
            <thead>
                <tr>
                    <th class="heading name">Descripcion</th>
                    <th class="heading amount">Precio</th>
                </tr>
            </thead>
        
            <tbody>
            ${listaProductos.map(producto => {
                return `<tr>
                <td>${producto.description}</td>
                <td class="price">${producto.price}</td>
                </tr>`
                }).join('')}
                <tr>
                    <td colspan="1" class="sum-up line">Subtotal</td>
                    <td class="line price">${subtotal}</td>
                </tr>
                <tr>
                    <th colspan="1" class="total text">Alicuota 21,00%</th>
                    <th class="total price">${iva}</th>
                </tr>
                <br/>
                <tr>
                    <th colspan="1" class="total text">Total</th>
                    <th class="total price">${total}</th>
                </tr>


            </tbody>
        </table>
        <section>
            <p>
                Forma de pago : <span>${formaPago}</span>
            </p>
            <p>
                Fecha de emision : <span>${moment(fechaEmision).format("DD/MM/YYYY")}</span>
            </p>
            <p>
                CAE : <span>${CAE}</span>
            </p>
            <p>
                Vencimiento CAE : <span>${moment(vencimientoCAE).format('DD/MM/YYYY')}</span>
            </p>
            ${afipQR&&`
            <div class="qr-code">
                <img width="100px" src="${afipQR}"/>
            </div>`}
        </section>
        <footer style="text-align:center">
            <p>¡Gracias por su visita!</p>
        </footer>
    </body>

    </html>`
    }


export const TicketX=  (fechaEmision,formaPago,listaProductos,cliente, fechaVencimientoPago, nombreFantasia,informacionExtra) => 
`
<!DOCTYPE html>
<html lang="en">

<head>
    <style>


        @page {
            size: 2.8in 11in;
            margin-top: 0cm;
            margin-left: 0cm;
            margin-right: 0cm;
        }

        table {
            width: 100%;
        }

        tr {
            width: 100%;

        }

        h1 {
            text-align: center;
            vertical-align: middle;
        }

        #logo {
            width: 60%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            padding: 5px;
            margin: 2px;
            display: block;
            margin: 0 auto;
        }

        header {
            width: 100%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            vertical-align: middle;
        }

        .items thead {
            text-align: center;
        }

        .center-align {
            text-align: center;
        }

        .bill-details td {
            font-size: 12px;
        }

        .receipt {
            font-size: medium;
            font-weight: bold;
        }

        .items .heading {
            font-size: 12.5px;
            text-transform: uppercase;
            border-top:1px solid black;
            margin-bottom: 4px;
            border-bottom: 1px solid black;
            vertical-align: middle;
        }

        .items thead tr th:first-child,
        .items tbody tr td:first-child {
            width: 47%;
            min-width: 47%;
            max-width: 47%;
            word-break: break-all;
            text-align: left;
        }

        .items td {
            font-size: 12px;
            text-align: right;
            vertical-align: bottom;
        }

        .price::before {
            font-family: Arial;
            text-align: right;
        }

        .sum-up {
            text-align: right !important;
        }
        .total {
            font-size: 13px;
            border-top:1px dashed black !important;
            border-bottom:1px dashed black !important;
        }
        .total.text, .total.price {
            text-align: right;
        }
        .total.price {
            font-size: 16px;
        }
        .line {
            border-top:1px solid black !important;
        }
        .heading.amount {
            width: 10%;
        }
        .heading.qty {
            width: 5%
        }
        p {
            padding: 1px;
            margin: 0;
        }
        section, footer {
            font-size: 12px;
        }
        .qr-code{
            width:100px;
            height:auto;
            margin:auto;
          }
    </style>
</head>

<body style="margin: 0; padding: 0; font-family: 'PT Sans', sans-serif;">
    <header>
            <span class="receipt">${nombreFantasia || `Recibo de pago`}</span>
    </header>
    <br/>
    <table class="bill-details">
        <tbody>
            <tr>
                <th class="center-align" colspan="1"><span class="receipt">X</span></th>
            </tr>
        </tbody>
    </table>
    
    <table class="items">
        <thead>
            <tr>
                <th class="heading name">Descripcion</th>
                <th class="heading amount">Precio</th>
            </tr>
        </thead>
       
        <tbody>
        ${listaProductos.map(producto => {
            return `<tr>
            <td>${producto.description}</td>
            <td class="price">${producto.price}</td>
            </tr>`
            }).join('')}
            <tr>
                <td colspan="1" class="sum-up line">Subtotal</td>
                <td class="line price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</td>
            </tr>
            <tr>
                <th colspan="1" class="total text">Total</th>
                <th class="total price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</th>
            </tr>


        </tbody>
    </table>
    <section>
        <p>
            Cuenta de : <span>${`${cliente.fullName} ${cliente.dni??""}`}</span>
        </p>
        ${fechaVencimientoPago&&`<p>
            Pagado hasta : <span>${moment(fechaVencimientoPago).format("ddd DD MMM HH:mm")}</span>
        </p>`}
        <p>
            Forma de pago : <span>${formaPago}</span>
        </p>
        <p>
        Fecha y hora : <span>${moment(fechaEmision).format("DD/MM/YYYY HH:mm")}</span>
        </p>
        <br/>
        ${informacionExtra.map(info => 
            {
                if(info.type === "QR"){
                    return `
                    ${info.key} : 
                    <div class="qr-code">
                    <img width="100px" src="${info.valueQR}"/>
                </div>`
                }
                return `<p>
                ${info.key} : <span>${info.value}</span>
                </p>`
            } 
        ).join('')}
        <br/>
        <br/>
        <p style="text-align:center">
            DOCUMENTO NO VALIDO COMO FACTURA
        </p>
     
    </section>
    <footer style="text-align:center">
        <p>¡Gracias por su visita!</p>
    </footer>
</body>

</html>`


export const CleaningReportInAndOut=({entran, salen, time})=>`
<!DOCTYPE html>
<html>
<head>
<title>Page Title</title>
<style>
body{
color: #000000;
font-width: 100%;

}
p{
margin: 0px 0px 0px 0px;
font-size:17px !important;
line-height:14px;
}
.bold{
font-weight: bold;
}
br{
    line-height:7px;
}
</style>
</head>
<body>
<h2>Fecha: ${time}</h2>
<h2>Entradas: ${entran.length}</h2>
${entran.map(x=>`
    <p>Nombre: <span class="bold">${x.customersList.at(0)?.fullName}</span></p>
    <p>Reserva:<span class="bold">${Object.keys(DevolverComponentesConServicios(DevolverComponentes(x),x.servicesList)).reduce((acom,current)=>(DevolverComponentesConServicios(DevolverComponentes(x),x.servicesList)[current]!=0?`${DevolverComponentesConServicios(DevolverComponentes(x),x.servicesList)[current]} ${current} `:"")+acom,"")}</span> <span>(${x.way})</span></p>
    <p>Lugar: <span class="bold">${x.roomsList.reduce((acom,current)=>`${current.number} ${acom}`,"")??"Sin Lugar"}</span></p>
    <p>Vehiculo: ${x.vehiclesList.map(x=>`${x.plate??''} ${x.color??''}`).toString()}</p>
    <p>${moment(x.checkinEstimated).format("DD MMM").split('.').join("")} --> ${moment(x.checkoutEstimated).format("DD MMM").split('.').join("")} (${x.nights} ${x.nights==1?"noche":"noches"})</p>
    <br/>
`).join("")}
------------------------------------------------
<h2>Salidas: ${salen.length}</h2>
${salen.map(x=>`
    <p>Nombre: <span class="bold">${x.customersList.at(0)?.fullName}</span></p>
    <p>Reserva:<span class="bold">${Object.keys(DevolverComponentesConServicios(DevolverComponentes(x),x.servicesList)).reduce((acom,current)=>(DevolverComponentesConServicios(DevolverComponentes(x),x.servicesList)[current]!=0?`${DevolverComponentesConServicios(DevolverComponentes(x),x.servicesList)[current]} ${current} `:"")+acom,"")}</span> <span>(${x.way})</span><</p>
    <p>Lugar: <span class="bold">${x.roomsList.reduce((acom,current)=>`${current.number} ${acom}`,"")??"Sin Lugar"}</span></p>
    <p>Vehiculo: ${x.vehiclesList.map(x=>`${x.plate??''} ${x.color??''}`).toString()}</p>
    <p>${moment(x.checkinEstimated).format("DD MMM").split('.').join("")} --> ${moment(x.checkoutEstimated).format("DD MMM").split('.').join("")} (${x.nights} ${x.nights==1?"noche":"noches"})</p>
    <br/>
`).join("")}
</body>
</html>
`

export const CleaningReport=({label, listado, time})=>`
<!DOCTYPE html>
<html>
<head>
<title>Page Title</title>
<style>
body{
color: #000000;
font-width: 100%;

}
p{
margin: 0px 0px 0px 0px;
font-size:17px !important;
line-height:14px;
}
.bold{
font-weight: bold;
}
br{
    line-height:7px;
}
</style>
</head>
<body>
<h2>Fecha: ${time}</h2>
<h2>${label}: ${listado.length}</h2>
${listado.map(x=>`
    <p>Nombre: <span class="bold">${x.customersList.at(0)?.fullName}</span></p>
    <p>Reserva:<span class="bold">${Object.keys(DevolverComponentesConServicios(DevolverComponentes(x),x.servicesList)).reduce((acom,current)=>(DevolverComponentesConServicios(DevolverComponentes(x),x.servicesList)[current]!=0?`${DevolverComponentesConServicios(DevolverComponentes(x),x.servicesList)[current]} ${current} `:"")+acom,"")}</span> <span>(${x.way})</span></p>
    <p>Lugar: <span class="bold">${x.roomsList.reduce((acom,current)=>`${current.number} ${acom}`,"")??"Sin Lugar"}</span></p>
    <p>Vehiculo: ${x.vehiclesList.map(x=>`${x.plate??''} ${x.color??''}`).toString()}</p>
    <p>${moment(x.checkinEstimated).format("DD MMM").split('.').join("")} --> ${moment(x.checkoutEstimated).format("DD MMM").split('.').join("")} (${x.nights} ${x.nights==1?"noche":"noches"})</p>
    <br/>
`).join("")}
</body>
</html>
`

//Checkin signature version with html
export const CashWithdrawReport = ({withdrawType,concept, amount, time}) => (`
    <html>
    <head>
    <style>
    body{
        font-family: Arial, Helvetica, sans-serif;
        text-align:center;
        font-size: 16px;
    }
    tr{
        text-align:center;
    }
    </style>
    </head>
    <body style="text-align: start">
    <div style="width:100%;">
    Tipo de retiro: <span style="font-weight:bold"">${withdrawType}</span>
    </div>
    <div style="width:100%">
    Concepto: <span style="font-weight:bold"">${concept}</span>
    </div>
    <div style="width:100%">
    Valor: <span style="font-weight:bold"">$${amount}</span>
    </div>
    <div style="width:100%">
    Dia/Hora: <span style="font-weight:bold"">${time}</span>
    </div>
    </body>
    </html>
    `)